import {Component, Input} from '@angular/core';
import {PlusOutline} from '@ant-design/icons-angular/icons';
import {iconProvider} from '@app/shared/icon.module';
import {SharedModule} from '@app/shared/shared.module';
import {User} from '@generated/models';
import {GuestMenuComponent} from './guest-menu.component';
import {UserMenuComponent} from './user-menu.component';

@Component({
  selector: 'app-main-topbar',
  standalone: true,
  imports: [SharedModule, GuestMenuComponent, UserMenuComponent],
  providers: [iconProvider([PlusOutline])],
  template: `
    <div class="p-4 h-18 flex items-center justify-between bg-black">
      <div class="basis-1/2 md:basis-1/3">
        <a [routerLink]="['/']">
          <picture class="!h-7">
            <source srcset="assets/img/logo.svg" media="(min-width: 768px)" />
            <img class="!h-7" src="assets/img/logo-icon.svg" />
          </picture>
        </a>
      </div>
      @if (sessionExists !== null) {
        @if (user === null) {
          @if (!loading) {
            <div class="basis-2/3">
              <app-guest-menu />
            </div>
          }
        } @else {
          <div class="basis-2/3">
            <app-user-menu [capabilities]="user.capabilities" />
          </div>
        }
      }
    </div>
  `,
})
export class MainTopbarComponent {
  @Input({required: true}) user: User | null = null;
  @Input({required: true}) sessionExists: boolean | null = null;
  @Input({required: true}) loading: boolean = true;
}
