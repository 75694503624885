import {inject} from '@angular/core';
import {TransferService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs';
import {
  RemoveTransferMedia,
  RemoveTransferMediaFailed,
  RemoveTransferMediaSuccess,
} from '../../actions/upload.actions';

export const removeTransferMedia = createEffect(
  (actions$ = inject(Actions), transferService = inject(TransferService)) => {
    return actions$.pipe(
      ofType(RemoveTransferMedia),
      switchMap((action) =>
        transferService
          .deleteTransferMedia({
            transferId: action.transferId,
          })
          .pipe(
            map(() => RemoveTransferMediaSuccess()),
            catchError(() => {
              return [RemoveTransferMediaFailed()];
            }),
          ),
      ),
    );
  },
  {functional: true},
);
