import {inject} from '@angular/core';
import {TransferPrice} from '@generated/models';
import {TransferService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, iif, map, of, switchMap} from 'rxjs';
import {
  GetPrice,
  GetPriceFailed,
  GetPriceSuccess,
} from '../../actions/upload.actions';

export const getPrice = createEffect(
  (actions$ = inject(Actions), transferService = inject(TransferService)) => {
    return actions$.pipe(
      ofType(GetPrice),
      switchMap((action) =>
        iif(
          () => action.payload === null,
          of(null),
          transferService.getTransferPrice({body: action.payload!}),
        ).pipe(
          map((payload: TransferPrice | null) => GetPriceSuccess({payload})),
          catchError(() => [GetPriceFailed()]),
        ),
      ),
    );
  },
  {functional: true},
);
