import {inject} from '@angular/core';
import {ChatwootService} from '@app/core/chatwoot/chatwoot.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs';
import {ClearUser} from '../../actions/user.action';

export const clearChatwootUser = createEffect(
  (actions$ = inject(Actions), chatwootService = inject(ChatwootService)) => {
    return actions$.pipe(
      ofType(ClearUser),
      tap(() => chatwootService.clearUser())
    );
  },
  {functional: true, dispatch: false}
);
