import {createSelector} from '@ngrx/store';
import {CORE_SELECTOR} from '../core.store';
import {chatwootStateKey} from '../reducers/chatwoot.recuder';

const selectChatwootState = createSelector(
  CORE_SELECTOR,
  (state) => state[chatwootStateKey]
);

export const selectChatwootReady = createSelector(
  selectChatwootState,
  (state) => state.ready
);
