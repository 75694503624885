import {inject} from '@angular/core';
import {ChatwootService} from '@app/core/chatwoot/chatwoot.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {filter, switchMap, take, tap} from 'rxjs';
import {GetMeSuccess} from '../../actions/user.action';
import {selectChatwootReady} from '../../selectors/chatwoot.selector';

export const setChatwootUser = createEffect(
  (
    actions$ = inject(Actions),
    chatwootService = inject(ChatwootService),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(GetMeSuccess),
      switchMap((action) =>
        store.select(selectChatwootReady).pipe(
          filter((chatwootReady) => chatwootReady !== null),
          take(1),
          tap((chatwootReady) => {
            if (chatwootReady) {
              chatwootService.setUser(action.user);
            }
          })
        )
      )
    );
  },
  {functional: true, dispatch: false}
);
