import {Component} from '@angular/core';
import {SharedModule} from '@app/shared/shared.module';

@Component({
  selector: 'app-email-verification-required',
  standalone: true,
  imports: [SharedModule],
  template: `<section class="bg-white">
    <div class="py-3 text-xs font-medium ">
      <span i18n
        >You have not yet verified your email address. Please check your email
        or
        <a class="underline " [routerLink]="['/', 'account', 'verification']"
          >click here</a
        >
        to request a new verification code.</span
      >
    </div>
  </section>`,
})
export class EmailVerificationRequiredComponent {}
