import {inject} from '@angular/core';
import {CanMatchFn, Router} from '@angular/router';
import {
  CheckSession,
  CheckSessionFailed,
  CheckSessionSuccess,
} from '@app/core/store/actions/user.action';
import {
  selectSessionExists,
  selectUser,
} from '@app/core/store/selectors/user.selector';
import {hasCapability} from '@app/shared/functions/has-capability';
import {User, UserCapability} from '@generated/models';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {iif, of, race} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';

export const canMatchAdminArea: CanMatchFn = () => {
  const router = inject(Router);
  const store = inject(Store);
  const action$ = inject(Actions);

  const checkSessionFailed$ = action$.pipe(
    ofType(CheckSessionFailed),
    take(1),
    map(() => router.createUrlTree(['/', 'account', 'login']))
  );

  const checkSessionSuccess$ = action$.pipe(
    ofType(CheckSessionSuccess),
    take(1),
    map(() => true)
  );

  return store.select(selectSessionExists).pipe(
    take(1),
    switchMap((sessionExists: boolean | null) =>
      iif(
        () => {
          if (sessionExists !== true) {
            store.dispatch(CheckSession());
          }
          return sessionExists === true;
        },
        of(true),
        race(checkSessionFailed$, checkSessionSuccess$)
      )
    ),
    switchMap((value) =>
      iif(
        () => typeof value === 'boolean',
        store.select(selectUser).pipe(
          filter((user: User | null): user is User => user !== null),
          take(1),
          map(
            (user: User) =>
              hasCapability(
                user.capabilities,
                UserCapability.CanAccessAdminArea
              ) || router.createUrlTree(['/', 'restricted-area'])
          )
        ),
        of(value)
      )
    )
  );
};
