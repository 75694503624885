import {inject} from '@angular/core';
import {AmplitudeService} from '@app/core/amplitude/amplitude.service';
import {UppyServices} from '@app/core/services/uppy.services';
import {UppyEvent, UppyItem} from '@app/shared/uppy';
import {
  AddedTransfer,
  AddTransfer,
  AddTransferFile,
  AddTransferMedia,
  AddTransferPreview,
  Config,
  TransferFile,
} from '@generated/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Action, Store} from '@ngrx/store';
import {catchError, distinctUntilChanged, map, switchMap} from 'rxjs';
import {ShowMessage} from '../../actions/layout.action';
import {
  UploadCompleted,
  UploadFileCompleted,
  UploadItems,
  UploadItemsFail,
  UploadMediaCompleted,
  UploadPreviewCompleted,
  UploadSetProgress,
} from '../../actions/upload.actions';
import {selectConfigurations} from '../../selectors/configurations.selector';
import {
  selectUploadRequest,
  selectUploadResponse,
} from '../../selectors/upload.selector';

export const uploadItems = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    amplitude = inject(AmplitudeService),
    uppyServices = inject(UppyServices),
  ) => {
    return actions$.pipe(
      ofType(UploadItems),
      concatLatestFrom(() => [
        store.select(selectUploadRequest),
        store.select(selectUploadResponse),
        store.select(selectConfigurations),
        amplitude.deviceId(),
      ]),
      switchMap(
        ([action, uploadRequest, uploadResponse, configuration, deviceId]: [
          unknown,
          AddTransfer | null,
          AddedTransfer | null,
          Config | null,
          string | undefined,
        ]) => {
          const files: UppyItem[] = (uploadRequest?.files ?? []).map(
            (file: AddTransferFile) => {
              const transferFile = uploadResponse?.files.find(
                (transferFile: TransferFile) =>
                  transferFile.uploadId === file.uploadId,
              );
              return {
                volume: transferFile!.volume,
                size: transferFile!.size,
                uploadId: file.uploadId,
                meta: {
                  transferId: uploadResponse?.id,
                  transferFileId: transferFile?.id,
                },
                instance: 'transferFile',
              };
            },
          );
          const media: UppyItem[] = (
            uploadRequest?.media ? [uploadRequest.media] : []
          ).map((file: AddTransferMedia) => {
            const transferMedia = uploadResponse?.media;
            return {
              volume: transferMedia!.volume,
              size: transferMedia!.size,
              uploadId: file.uploadId,
              meta: {
                transferId: uploadResponse?.id,
                transferMediaId: transferMedia?.id,
              },
              instance: 'transferMedia',
            };
          });

          const preview: UppyItem[] = (
            uploadRequest?.preview ? [uploadRequest.preview] : []
          ).map((file: AddTransferPreview) => {
            const transferPreview = uploadResponse?.preview;
            return {
              volume: transferPreview!.volume,
              size: transferPreview!.size,
              uploadId: file.uploadId,
              meta: {
                transferId: uploadResponse?.id,
                transferPreviewId: transferPreview?.id,
              },
              instance: 'transferPreview',
            };
          });

          return uppyServices
            .uploadItems({
              files: [...files, ...media, ...preview],
              configuration: configuration!,
              deviceId,
            })
            .pipe(
              distinctUntilChanged(),
              map((res) => {
                const handlers: Record<UppyEvent['event'], () => Action> = {
                  'complete': () => UploadCompleted({payload: uploadResponse!}),
                  'progress': () =>
                    UploadSetProgress({progress: res.progress!}),
                  'upload-success': () => {
                    const handlers: Record<string, () => Action> = {
                      'transferMedia': () =>
                        UploadMediaCompleted({
                          transferMediaId: res.meta!.transferMediaId as string,
                          transferId: res.meta!.transferId as string,
                        }),
                      'transferFile': () =>
                        UploadFileCompleted({
                          transferFileId: res.meta!.transferFileId as string,
                          transferId: res.meta!.transferId as string,
                        }),
                      'transferPreview': () =>
                        UploadPreviewCompleted({
                          transferPreviewId: res.meta!
                            .transferPreviewId as string,
                          transferId: res.meta!.transferId as string,
                        }),
                    };
                    if (
                      typeof res.meta?.type === 'string' &&
                      handlers[res.meta.type]
                    ) {
                      return handlers[res.meta.type]();
                    }
                    throw new Error('UNEXPECTED');
                  },
                };
                return handlers[res.event]();
              }),
              catchError(() => [
                UploadItemsFail(),
                ShowMessage({
                  payload: {
                    type: 'error',
                    message: $localize`Oh-oh, unable to load all items.`,
                  },
                }),
              ]),
            );
        },
      ),
    );
  },
  {functional: true},
);
