import {
  HttpContextToken,
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {from, throwError} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {AmplitudeService} from '../amplitude/amplitude.service';
import {AMPLITUDE_DEVICE_ID} from '../amplitude/constants';

export const SET_AMPLITUDE_DEVICE_ID = new HttpContextToken<boolean>(
  () => true
);

export const amplitudeInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const router = inject(Router);
  const amplitude = inject(AmplitudeService);

  const shouldSetAmplitudeDeviceId = req.context.get(SET_AMPLITUDE_DEVICE_ID);

  if (shouldSetAmplitudeDeviceId === false) {
    return next(req);
  }

  // return this.amplitude.getDeviceId();

  return amplitude.deviceId().pipe(
    exhaustMap((deviceId: string | undefined) => {
      const modifiedReq = deviceId
        ? req.clone({
            headers: req.headers.set(AMPLITUDE_DEVICE_ID, `${deviceId}`),
          })
        : req;

      return next(modifiedReq);
    })
  );
};
