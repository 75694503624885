import {createSelector} from '@ngrx/store';
import {CORE_SELECTOR} from '../core.store';
import {cookieYesStateKey} from '../reducers/cookie-yes.reducer';

const selectCookieYesState = createSelector(
  CORE_SELECTOR,
  (state) => state[cookieYesStateKey]
);

export const selectCookieYesConsent = createSelector(
  selectCookieYesState,
  (state) => state.consent
);
