import {inject} from '@angular/core';
import {TransferService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NzMessageService} from 'ng-zorro-antd/message';
import {catchError, mergeMap, throwError} from 'rxjs';
import {UploadFileCompleted} from '../../actions/upload.actions';

// TODO rimuovere nzmessageservice
export const uploadFileCompleted = createEffect(
  (
    actions$ = inject(Actions),
    transferService = inject(TransferService),
    nzMessageService = inject(NzMessageService),
  ) => {
    return actions$.pipe(
      ofType(UploadFileCompleted),
      mergeMap(
        (action) =>
          transferService
            .finalizeTransferFile({
              transferId: action.transferId,
              fileId: action.transferFileId,
            })
            .pipe(
              catchError((error) => {
                nzMessageService.error(
                  $localize`Oh-oh, unable to load all items.`,
                );
                return throwError(() => error);
              }),
            ),
        1,
      ),
    );
  },
  {functional: true, dispatch: false},
);
