import {DOCUMENT} from '@angular/common';
import {Injectable, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {environment} from '@app/env/environment';
import {Store} from '@ngrx/store';
import {Observable, filter, fromEvent, shareReplay} from 'rxjs';
import {UpdateCookieYesConsent} from '../store/actions/cookie-yes.action';
import {selectCookieYesConsent} from '../store/selectors/cookie-yes.selector';
import {CookieYesConsent, CookieYesType} from './types';

@Injectable({
  providedIn: 'root',
})
export class CookieYesService {
  private readonly document = inject(DOCUMENT);
  private readonly store = inject(Store);

  cookieYesConsent$: Observable<CookieYesConsent> = this.store
    .select(selectCookieYesConsent)
    .pipe(
      filter(
        (consent: CookieYesConsent | null): consent is CookieYesConsent =>
          consent !== null
      ),
      shareReplay()
    );

  constructor() {
    // FIXME forzata abilitazione dei cookie (2024-07-29)

    // https://www.cookieyes.com/documentation/events-on-cookie-banner-interactions/
    // fromEvent(this.document, 'cookieyes_consent_update')
    //   .pipe(takeUntilDestroyed())
    //   .subscribe((event: any) => {
    //     this.store.dispatch(UpdateCookieYesConsent({consent: event['detail']}));
    //   });
    // const consent = this.#getInitialConsent();

    const consent: CookieYesConsent = {
      accepted: ['analytics', 'necessary'],
      rejected: [],
    };
    this.store.dispatch(UpdateCookieYesConsent({consent}));
  }

  #getInitialConsent(): CookieYesConsent {
    //https://www.cookieyes.com/documentation/implement-prior-consent-using-cookieyes/

    const getCookie = (key: string) => {
      const cookies = (this.document.cookie ?? '')
        .split(';')
        .reduce(
          (ac: any, cv) =>
            Object.assign(ac, {[cv.split('=')[0].trim()]: cv.split('=')[1]}),
          {}
        )['cookieyes-consent'];
      const {[key]: value} = (cookies ?? '')
        .split(',')
        .reduce(
          (obj: any, pair: any) => (
            (pair = pair.split(':')), (obj[pair[0]] = pair[1]), obj
          ),
          {}
        );

      return value ?? '';
    };

    if (environment.production !== true) {
      return {accepted: ['analytics', 'necessary'], rejected: []};
    }

    if (getCookie('consent') == 'yes') {
      const analytics: CookieYesType[] =
        getCookie('analytics') == 'yes' ? ['analytics'] : [];
      return {
        accepted: ['necessary', ...analytics],
        rejected: [],
      };
    }
    return {accepted: [], rejected: []};
  }

  init() {
    if (environment.production) {
      const script = this.document.createElement('script');
      script.src =
        'https://cdn-cookieyes.com/client_data/d1edb917f7040d0dc74c01aa/script.js';
      script.type = 'text/javascript';
      script.id = 'cookieyes';
      this.document.getElementsByTagName('head')[0].appendChild(script);
    }
  }
}
