import {DOCUMENT} from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {iconProvider} from '@app/shared/icon.module';
import {SharedModule} from '@app/shared/shared.module';
import {FtMenuOutline} from '@icons/FtMenuOutline';
import {filter} from 'rxjs';

@Component({
  selector: 'app-menu-drawer',
  standalone: true,
  imports: [SharedModule],
  providers: [iconProvider([FtMenuOutline])],
  template: `
    <button
      class="!text-white"
      nz-button
      nzType="text"
      nzPlacement="bottomRight"
      (click)="toggle()"
    >
      <span nz-icon nzType="ft-menu" nzTheme="outline"></span>
    </button>
    <div
      class="fixed transition-opacity ease-linear h-screen duration-300 top-[72px] left-0 w-full bg-black z-20"
      (click)="close()"
      [ngClass]="{
        'opacity-60': expanded,
        'opacity-0 pointer-events-none': !expanded,
      }"
    ></div>
    <div
      class="fixed transition-[width] duration-300 top-[4.5rem] right-0 h-screen z-20 overflow-hidden"
      [ngClass]="{
        'w-60': expanded,
        'w-0 opacity-80': !expanded,
      }"
    >
      <div class="bg-black py-6 px-4 h-[calc(100vh-4.5rem)] min-h-[480px]">
        <ng-content />
      </div>
    </div>
  `,
})
export class MenuDrawerComponent implements OnDestroy {
  private readonly document = inject(DOCUMENT);
  private readonly render2 = inject(Renderer2);
  private readonly router = inject(Router);

  @Input() expanded: boolean = false;

  @Output() collapse: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.close();
      });
  }

  ngOnDestroy(): void {
    this.close();
  }

  close(): void {
    this.render2.removeClass(this.document.querySelector('body'), 'noscroll');
    this.expanded = false;
    this.collapse.emit(true);
  }

  toggle(): void {
    this.expanded ? this.close() : this.expand();
  }

  expand(): void {
    this.render2.addClass(this.document.querySelector('body'), 'noscroll');
    this.expanded = true;
    this.collapse.emit(false);
  }
}
