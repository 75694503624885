import {DOCUMENT} from '@angular/common';
import {inject, Inject, Injectable, LOCALE_ID} from '@angular/core';
import {environment} from '@app/env/environment';
import {User} from '@generated/models';
import {Store} from '@ngrx/store';
import {UpdateReady} from '../store/actions/chatwoot.action';
import {LANGUAGE} from '../constants';

export type Chatwoot = {
  toggleBubbleVisibility: (visibility?: 'show' | 'hide') => void;
  setUser: (id: string, data: Record<string, string>) => void;
  reset: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class ChatwootService {
  private readonly store: Store = inject(Store);
  private readonly rel = 'chatwoot';
  private windowRef = window as any;
  private readonly language = inject(LANGUAGE);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.windowRef.chatwootSettings = this.windowRef.chatwootSettings ?? {
      hideMessageBubble: false,
      position: 'right', // This can be left or right
      locale: this.language, // Language to be set
      useBrowserLanguage: false, // Set widget language from user's browser
      darkMode: 'auto', // [light, auto],
      type: 'expanded_bubble',
      launcherTitle: $localize`Need help?`,
    };

    this.windowRef.addEventListener('chatwoot:ready', () => {
      this.store.dispatch(
        UpdateReady({
          ready: true,
        }),
      );
    });
  }

  load() {
    const chatwootConfig = environment.chatwoot;
    if (chatwootConfig) {
      const {baseUrl, websiteToken} = chatwootConfig;
      const s = this.document.createElement('script');
      const tag = this.document.getElementsByTagName('script')[0];
      s.src = `${baseUrl}/packs/js/sdk.js`;
      s.setAttribute('rel', this.rel);
      s.async = !0;
      s.onload = () => {
        this.windowRef.chatwootSDK.run({
          websiteToken,
          baseUrl,
        });
      };
      tag.parentNode?.insertBefore(s, tag);
    } else {
      this.store.dispatch(
        UpdateReady({
          ready: false,
        }),
      );
    }
  }

  setUser(user: User) {
    this.windowRef.$chatwoot.setUser(user.id, {
      name: user.displayName, // Name of the user
      email: user.email, // Email of the user
      identifier_hash: user.chatwootHash, // Identifier Hash generated based on the webwidget hmac_token
      city: user.billingInfo?.city, // City of the user
    });
  }

  clearUser() {
    this.windowRef.$chatwoot?.reset();
  }
}
