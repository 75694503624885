import {AsyncPipe, NgIf} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {RouterOutlet} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, shareReplay} from 'rxjs';
import {IamWithNgZorroModule} from './core/iam-with-ngzorro/iam-with-ngzorro.module';
import {CookieYesService} from './core/cookie-yes/cookie-yes.service';
import {MetaService} from './core/services/meta.service';
import {HideToast} from './core/store/actions/layout.action';
import {Toast} from './core/store/interfaces/layout-state.interface';
import {selectToast} from './core/store/selectors/layout.selector';
import {ToastComponent} from './shared/components/toast/toast.component';
import {AmplitudeService} from './core/amplitude/amplitude.service';
import {CookieYesConsent} from './core/cookie-yes/types';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {environment} from '@app/env/environment';
import {Chatwoot, ChatwootService} from './core/chatwoot/chatwoot.service';

@Component({
  selector: 'app-root',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    IamWithNgZorroModule,
    NgIf,
    AsyncPipe,
    ToastComponent,
  ],
  template: `<router-outlet /><iam-with-ngzorro-content></iam-with-ngzorro-content>
    <app-toast
      *ngIf="toast$ | async as toast"
      [data]="toast"
      (close)="hideToast()"
    /> `,
})
export class AppComponent {
  private readonly store: Store = inject(Store);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly amplitude: AmplitudeService = inject(AmplitudeService);
  private readonly metaService: MetaService = inject(MetaService);
  private readonly titleService: Title = inject(Title);
  private readonly cookieYesService: CookieYesService =
    inject(CookieYesService);
  private readonly chatwootService: ChatwootService = inject(ChatwootService);

  readonly toast$: Observable<Toast | null> = this.store.select(selectToast);

  constructor() {
    this.titleService.setTitle(
      $localize`Fulltransfer - Earn from sharing your file.`
    );
    this.metaService.updateMetaTag();
    this.cookieYesService.init();
    this.chatwootService.load();
  }

  hideToast(): void {
    this.store.dispatch(HideToast());
  }
}
