import {Injectable} from '@angular/core';
import {Affiliate, Affiliation} from '@generated/models';
import {AffiliationService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NzMessageService} from 'ng-zorro-antd/message';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {
  GetAffiliation,
  GetAffiliationFailed,
  GetAffiliationSuccess,
  JoinAffiliation,
  JoinAffiliationFailed,
  JoinAffiliationSuccess,
  LeaveAffiliation,
  LeaveAffiliationFailed,
  LeaveAffiliationSuccess,
} from '../actions/affiliation.action';
import {SetRestrictedAreaReferralActiveTab} from '../actions/layout.action';
import {Go} from '../actions/router.action';
import {GetMe} from '../actions/user.action';

@Injectable()
export class AffiliationEffects {
  constructor(
    private actions$: Actions,
    private readonly affiliationService: AffiliationService,
    private nzMessageService: NzMessageService
  ) {}

  getAffiliation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAffiliation),
      switchMap(() =>
        this.affiliationService.readAffiliation().pipe(
          switchMap((affiliation: Affiliation) => [
            GetAffiliationSuccess({affiliation}),
          ]),
          catchError(() => [GetAffiliationFailed()])
        )
      )
    )
  );

  joinAffiliation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinAffiliation),
      switchMap((action) => {
        return this.affiliationService
          .joinAffiliation({
            body: action.dto,
          })
          .pipe(
            tap(() => {
              this.nzMessageService.success(
                $localize`Successfully joined partner affiliation program`
              );
            }),
            switchMap((affiliate: Affiliate) => [
              JoinAffiliationSuccess({affiliate}),
              SetRestrictedAreaReferralActiveTab({tabIndex: 2}),
              Go({payload: {path: ['/', 'restricted-area', 'referral']}}),
            ]),
            catchError(() => {
              this.nzMessageService.error(
                $localize`The referral affiliation code is not valid`
              );
              return [JoinAffiliationFailed()];
            })
          );
      })
    )
  );

  leaveAffiliation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveAffiliation),
      switchMap(() =>
        this.affiliationService.leaveAffiliation().pipe(
          tap(() => {
            this.nzMessageService.success(
              $localize`Successfully left partner affiliation program`
            );
          }),
          switchMap(() => [LeaveAffiliationSuccess(), GetMe()]),
          catchError(() => {
            this.nzMessageService.error($localize`Unexpected error`);
            return [LeaveAffiliationFailed()];
          })
        )
      )
    )
  );
}
