import {inject} from '@angular/core';
import {AmplitudeService} from '@app/core/amplitude/amplitude.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of, switchMap} from 'rxjs';
import {UpdateCookieYesConsent} from '../../actions/cookie-yes.action';

export const updateCookieYesConsent = createEffect(
  (actions$ = inject(Actions), amplitude = inject(AmplitudeService)) => {
    return actions$.pipe(
      ofType(UpdateCookieYesConsent),
      switchMap(({consent}) => {
        if (consent.accepted.includes('analytics')) {
          return amplitude.enableTracking();
        } else {
          return amplitude.disableTracking();
        }
      })
    );
  },
  {functional: true, dispatch: false}
);
