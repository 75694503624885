import {Component} from '@angular/core';
import {FlowNodeTemplateKind} from '@fullstackagency/iam';
import {UiNodeTypeEnum} from '@ory/client';

@Component({
  selector: 'iam-with-ngzorro-content',
  template: ` <ng-template iamMessages let-m>
      <div *ngIf="m.length" class="mb-6 text-sm">
        <iam-with-ngzorro-messages [messages]="m" />
      </div>
    </ng-template>

    <ng-template [iamTemplate]="FlowNodeTemplateKind.Anchor" let-a>
      <a
        *ngIf="a.nodeType === UiNodeTypeEnum.A"
        nz-button
        nzType="primary"
        class="w-full"
        nzSize="large"
        [href]="a.href"
        >{{ a.title }}</a
      >
    </ng-template>

    <ng-template [iamTemplate]="FlowNodeTemplateKind.Image">{{
      FlowNodeTemplateKind.Image
    }}</ng-template>

    <ng-template [iamTemplate]="FlowNodeTemplateKind.Input" let-a>
      <div
        *ngIf="a.nodeType === UiNodeTypeEnum.Input"
        class="mb-2"
        [ngClass]="{'hidden': a.type === 'hidden'}"
      >
        <label class="text-sm font-medium">{{
          $any(a['node'].meta)?.['fieldLabel'] ?? a.label
        }}</label>
        @if (a.type !== 'password') {
          <input
            nz-input
            [autocomplete]="a.autocomplete"
            [disabled]="a.disabled"
            [name]="a.name"
            [required]="a.required"
            [type]="a.type"
            [readonly]="$any(a)?.attributes?.['readonly'] ?? false"
            [placeholder]="$any(a['node'].meta)?.['placeholder'] ?? ''"
            [value]="a.value"
          />
        } @else {
          <nz-input-group [nzSuffix]="suffixTemplate">
            <input
              #pwdInput
              [attr.data-visible]="'off'"
              [type]="
                pwdInput.dataset['visible'] === 'on' ? 'text' : 'password'
              "
              nz-input
              [autocomplete]="a.autocomplete"
              [disabled]="a.disabled"
              [name]="a.name"
              [required]="a.required"
              [type]="a.type"
              [value]="a.value"
            />
          </nz-input-group>
          <ng-template #suffixTemplate>
            <span
              class="cursor-pointer"
              nz-icon
              [nzType]="
                pwdInput.dataset['visible'] === 'on' ? 'eye-invisible' : 'eye'
              "
              (click)="
                pwdInput.dataset['visible'] =
                  pwdInput.dataset['visible'] === 'on' ? 'off' : 'on'
              "
            ></span>
          </ng-template>
        }
        <div class="text-xs">
          <iam-with-ngzorro-messages [messages]="a.messages" />
        </div>
      </div>
    </ng-template>

    <ng-template
      [iamTemplate]="[
        FlowNodeTemplateKind.InputButton,
        FlowNodeTemplateKind.InputSubmit,
      ]"
      let-a
    >
      <div *ngIf="a.nodeType === UiNodeTypeEnum.Input">
        @if (a.value === 'google') {
          <button
            nz-button
            nzSize="large"
            class="btn-white w-full mb-4 !flex !items-center !justify-center"
            [name]="a.name"
            [type]="a.type"
            [value]="a.value"
          >
            <span nz-icon class="w-5 h-5">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="display: block;"
                class="h-full w-full"
              >
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                ></path>
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                ></path>
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                ></path>
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                ></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </span>
            <span>{{ a.label }}</span>
          </button>
        } @else {
          <button
            nz-button
            [nzType]="$any(a['node'].meta)?.['style'] ?? 'primary'"
            class="w-full mt-4"
            nzSize="large"
            [name]="a.name"
            [type]="a.type"
            [value]="a.value"
          >
            {{ $any(a['node'].meta)?.['fieldLabel'] ?? a.label }}
          </button>
        }
      </div>
    </ng-template>

    <ng-template [iamTemplate]="FlowNodeTemplateKind.Script">{{
      FlowNodeTemplateKind.Script
    }}</ng-template>

    <ng-template [iamTemplate]="FlowNodeTemplateKind.Text">{{
      FlowNodeTemplateKind.Text
    }}</ng-template>`,
})
export class ContentComponent {
  FlowNodeTemplateKind = FlowNodeTemplateKind;
  UiNodeTypeEnum = UiNodeTypeEnum;
}
