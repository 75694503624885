import {Routes} from '@angular/router';
import {canActivateApp} from './app.activate.guard';
import {configurationResolver} from './core/resolvers/configuration.resolver';
import {canMatchAdminArea} from './features/admin-area/admin-area.match.guard';
import {canMatchRestrictedArea} from './features/restricted-area/restricted-area.match.guard';
import {MainLayoutComponent} from './layout/main-layout.component';
import {mainLayoutResolver} from './layout/resolvers/main-layout.resolver';

export const routes: Routes = [
  {
    path: '',
    resolve: {
      config: configurationResolver,
    },
    canActivate: [canActivateApp],
    children: [
      {
        path: '',
        component: MainLayoutComponent,
        resolve: {
          data: mainLayoutResolver,
        },
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./features/free-area/free-area.routes').then(
                (m) => m.routes,
              ),
          },
          {
            path: 'restricted-area',
            canMatch: [canMatchRestrictedArea],
            loadChildren: () =>
              import('./features/restricted-area/restricted-area.routes').then(
                (m) => m.routes,
              ),
          },
          {
            path: 'admin-area',
            canMatch: [canMatchAdminArea],
            loadChildren: () =>
              import('./features/admin-area/admin-area.routes').then(
                (m) => m.routes,
              ),
          },
          {
            path: 'error',
            loadChildren: () =>
              import('./features/error/error.routes').then((m) => m.routes),
          },
          {
            path: 'account',
            loadChildren: () =>
              import('./features/account/account.routes').then((m) => m.routes),
          },
        ],
      },
    ],
  },
  {path: '**', redirectTo: '/error'},
];
