import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  InjectionToken,
  Input,
  Output,
  Type,
  inject,
} from '@angular/core';
import {CloseOutline} from '@ant-design/icons-angular/icons';
import {Toast} from '@app/core/store/interfaces/layout-state.interface';
import {SharedModule} from '@app/shared/shared.module';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {iconProvider} from '../../icon.module';
import {ToastStripeOnboardSuccessComponent} from './toast-stripe-onboard-success.component';

export const TOAST_COMPONENTS = new InjectionToken<Type<any>[]>(
  'TOAST_COMPONENTS',
);
@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [SharedModule, NzButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="fixed bottom-0 left-0 w-full min-h-10 flex items-center"
      [ngClass]="{
        'bg-green': type === 'success',
        'bg-error': type === 'error',
        'bg-gray': type === 'neutral',
      }"
    >
      <div class="max-w-screen-2xl mx-auto w-full flex items-center">
        <div class="w-12"></div>
        <div class="flex-1 flex justify-center items-center">
          <ng-container *ngIf="component; else textTemplate">
            <ng-container
              *ngComponentOutlet="component; inputs: componentInputs"
            />
          </ng-container>
          <ng-template #textTemplate>
            {{ text }}
          </ng-template>
        </div>
        <div class="w-12 text-right">
          <button
            nz-button
            nzType="link"
            *ngIf="closable"
            (click)="close.emit()"
          >
            <span nz-icon nzType="close"></span>
          </button>
        </div>
      </div>
    </div>
  `,
  providers: [
    iconProvider([CloseOutline]),
    {
      provide: TOAST_COMPONENTS,
      useValue: [ToastStripeOnboardSuccessComponent],
    },
  ],
})
export class ToastComponent {
  private readonly components = inject(TOAST_COMPONENTS).reduce(
    (map: Record<string, Type<any>>, current: Type<any>) => {
      map[current.name] = current;
      return map;
    },
    {},
  );

  componentInputs = {};
  component: Type<any> | undefined;
  text: string = '';
  type: Toast['type'] | undefined;
  closable: boolean = true;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Input({required: true}) set data(value: Toast) {
    this.component = value.component
      ? this.components[value.component]
      : undefined;
    this.text = value.text ?? '';
    this.type = value.type;
    this.closable = value.closable !== false;
    this.componentInputs = value.meta ?? {};
  }
}
