import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {
  ApplicationConfig,
  LOCALE_ID,
  importProvidersFrom,
  inject,
} from '@angular/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter} from '@angular/router';
import {environment} from '@app/env/environment';
import {ApiModule} from '@generated/api.module';
import {provideEffects} from '@ngrx/effects';
import {NZ_I18N, en_US, it_IT} from 'ng-zorro-antd/i18n';
import {routes} from './app.routes';
import {IamWithNgZorroModule} from './core/iam-with-ngzorro/iam-with-ngzorro.module';
import {amplitudeInterceptor} from './core/interceptors/amplitude.interceptor';
import {errorInterceptor} from './core/interceptors/error.interceptor';
import {unauthorizedInterceptor} from './core/interceptors/unauthorized.interceptor';
import {provideCoreStore} from './core/store/core.store';
import {AffiliationEffects} from './core/store/effects/affiliation.effects';
import * as amplitudeEffects from './core/store/effects/amplitude';
import * as chatwootEffects from './core/store/effects/chatwoot';
import {ConfigurationsEffect} from './core/store/effects/configuration.effects';
import * as cookieYesEffects from './core/store/effects/cookie-yes';
import {LayoutEffects} from './core/store/effects/layout.effects';
import {RouterEffects} from './core/store/effects/router.effects';
import * as uploadEffects from './core/store/effects/upload';
import {UserEffects} from './core/store/effects/user.effects';
import {LANGUAGE} from './core/constants';
import {Language} from '@generated/models';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    ...provideCoreStore(),
    provideEffects(
      RouterEffects,
      UserEffects,
      ConfigurationsEffect,
      LayoutEffects,
      AffiliationEffects,
      uploadEffects,
      chatwootEffects,
      amplitudeEffects,
      cookieYesEffects,
    ),
    provideHttpClient(
      withInterceptors([
        errorInterceptor,
        unauthorizedInterceptor,
        amplitudeInterceptor,
      ]),
    ),
    provideAnimations(),
    importProvidersFrom([
      ApiModule.forRoot({rootUrl: '/api'}),
      IamWithNgZorroModule,
    ]),
    ...environment.providers,
    {
      provide: NZ_I18N,
      useFactory: () => (inject(LANGUAGE) === Language.It ? it_IT : en_US),
    },
    {
      provide: LANGUAGE,
      useFactory: () => {
        const locale = inject(LOCALE_ID);
        const language = locale.substring(0, 2);
        return language === 'it' ? Language.It : Language.En;
      },
    },
  ],
};
