import {inject} from '@angular/core';
import {AmplitudeService} from '@app/core/amplitude/amplitude.service';
import {GetDownloadableTransferSuccess} from '@app/features/free-area/download/store/download.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {
  switchMap,
  filter,
  iif,
  of,
  Observable,
  concatMap,
  map,
  take,
} from 'rxjs';
import {selectSessionExists, selectUser} from '../../selectors/user.selector';
import {User} from '@generated/models';

export const transfeDownloadRequested = createEffect(
  (
    actions$ = inject(Actions),
    amplitude = inject(AmplitudeService),
    store = inject(Store),
  ) => {
    return actions$.pipe(
      ofType(GetDownloadableTransferSuccess),
      filter(
        (action) =>
          action.context === 'checkout' && action.payload.paid === true,
      ),
      switchMap(
        (
          action,
        ): Observable<{
          action: ReturnType<typeof GetDownloadableTransferSuccess>;
          user: User | null;
        }> => {
          return store.select(selectSessionExists).pipe(
            filter(
              (sessionExists): sessionExists is boolean =>
                sessionExists !== null,
            ),
            take(1),
            switchMap((sessionExists) => {
              return iif(
                () => sessionExists,
                store.select(selectUser).pipe(
                  filter((user) => user !== null),
                  take(1),
                  map((user) => ({action, user})),
                ),
                of({action, user: null}),
              );
            }),
          );
        },
      ),
      concatMap(
        (prev: {
          action: ReturnType<typeof GetDownloadableTransferSuccess>;
          user: User | null;
        }) =>
          amplitude.loaded$.pipe(
            take(1),
            map((loaded) => ({...prev, loaded})),
          ),
      ),
      switchMap(
        ({
          user,
          action,
          loaded,
        }: {
          action: ReturnType<typeof GetDownloadableTransferSuccess>;
          user: User | null;
          loaded: boolean;
        }) => [
          loaded &&
            amplitude.isLoaded &&
            amplitude.transferPaymentRequested(
              {
                transferId: action.payload.id!,
                transferType: action.payload.type,
                transferRecipientId: action.payload.recipients?.[0]?.id,
              },
              {user_id: user?.id},
            ),
        ],
      ),
    );
  },
  {functional: true, dispatch: false},
);
