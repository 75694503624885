import {DOCUMENT, Location} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Back, Forward, Go, Redirect} from '../actions/router.action';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Go),
        map((actions: any) => actions.payload),
        tap(({path, query, extras}) => {
          this.router.navigate(path, {...query, ...extras});
        })
      ),
    {dispatch: false}
  );

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Back),
        tap(() => this.location.back())
      ),
    {dispatch: false}
  );

  navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Forward),
        tap(() => this.location.forward())
      ),
    {dispatch: false}
  );

  redirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Redirect),
        tap((action) => {
          this.document.location.href = action.url;
        })
      ),
    {dispatch: false}
  );
}
