import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NzMessageService} from 'ng-zorro-antd/message';
import {iif, of} from 'rxjs';
import {delay, switchMap, tap} from 'rxjs/operators';
import {HideToast, ShowMessage, ShowToast} from '../actions/layout.action';

@Injectable()
export class LayoutEffects {
  constructor(
    private actions$: Actions,
    private readonly nzMessageService: NzMessageService
  ) {}

  showToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowToast),
      switchMap(({payload}) =>
        iif(
          () => payload.closable !== false,
          of(true).pipe(
            delay(30000),
            switchMap(() => [HideToast()])
          ),
          of(true).pipe(switchMap(() => []))
        )
      )
    )
  );

  showMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowMessage),
        tap(({payload}) =>
          this.nzMessageService.create(payload.type, payload.message)
        )
      ),
    {dispatch: false}
  );
}
