import {inject} from '@angular/core';
import {TransferService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {catchError, switchMap} from 'rxjs';
import {ShowMessage} from '../../actions/layout.action';
import {Go} from '../../actions/router.action';
import {
  AddTransfer,
  AddTransferFailed,
  AddTransferSuccess,
} from '../../actions/upload.actions';

export const addTransfer = createEffect(
  (actions$ = inject(Actions), transferService = inject(TransferService)) => {
    return actions$.pipe(
      ofType(AddTransfer),
      switchMap(({payload}) => {
        return transferService
          .addTransfer({
            body: {...payload},
          })
          .pipe(
            switchMap((res) => {
              let actions: Action[] = [
                Go({payload: {path: ['/', 'upload', 'progress']}}),
              ];
              if (res.sender?.isVerified === false) {
                actions = [
                  Go({payload: {path: ['/', 'upload', 'verify']}}),
                  ShowMessage({
                    payload: {
                      type: 'info',
                      message: $localize`Verification code sent successfully`,
                    },
                  }),
                ];
              }
              return [AddTransferSuccess({payload: res}), ...actions];
            }),
            catchError(() => [
              AddTransferFailed(),
              ShowMessage({
                payload: {
                  type: 'error',
                  message: $localize`Upload error`,
                },
              }),
            ]),
          );
      }),
    );
  },
  {functional: true},
);
