import {inject} from '@angular/core';
import {Transfer} from '@generated/models';
import {TransferService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs';
import {
  GetDuplicatedTransfer,
  GetDuplicatedTransferFailed,
  GetDuplicatedTransferSuccess,
} from '../../actions/upload.actions';

export const getDuplicateTransfer = createEffect(
  (actions$ = inject(Actions), transferService = inject(TransferService)) => {
    return actions$.pipe(
      ofType(GetDuplicatedTransfer),
      switchMap((action) =>
        transferService
          .readSentTransfer({
            transferId: action.id,
          })
          .pipe(
            map((transfer: Transfer) =>
              GetDuplicatedTransferSuccess({transfer}),
            ),
            catchError(() => [GetDuplicatedTransferFailed()]),
          ),
      ),
    );
  },
  {functional: true},
);
