import {Inject, inject, Injectable, LOCALE_ID} from '@angular/core';
import {ConfigurationService} from '@generated/services/configuration.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NzMessageService} from 'ng-zorro-antd/message';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {
  GetCities,
  GetCitiesFailed,
  GetCitiesSuccess,
  GetConfigurations,
  GetConfigurationsFailed,
  GetConfigurationsSuccess,
  GetCountries,
  GetCountriesFailed,
  GetCountriesSuccess,
  GetMcc,
  GetMccFailed,
  GetMccSuccess,
  GetStates,
  GetStatesFailed,
  GetStatesSuccess,
  LoadAllLocationsData,
} from '../actions/configuration.action';
import {HttpClient, HttpContext} from '@angular/common/http';
import {SET_AMPLITUDE_DEVICE_ID} from '@app/core/interceptors/amplitude.interceptor';
import {LANGUAGE} from '@app/core/constants';

const ASSET_BASE_PATH = 'assets/data';

@Injectable()
export class ConfigurationsEffect {
  constructor(
    private configurationService: ConfigurationService,
    private actions$: Actions,
    private nzMessageService: NzMessageService,
    private http: HttpClient,
    @Inject(LANGUAGE) private readonly language: string,
  ) {}

  getConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetConfigurations),
      switchMap(() => {
        return this.configurationService
          .readConfiguration(
            undefined,
            new HttpContext().set(SET_AMPLITUDE_DEVICE_ID, false),
          )
          .pipe(
            switchMap((res) => {
              return [GetConfigurationsSuccess({payload: res})];
            }),
            catchError(() => {
              this.nzMessageService.error($localize`Configurations not loaded`);
              return of(GetConfigurationsFailed());
            }),
          );
      }),
    ),
  );

  loadAllLocationsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAllLocationsData),
      switchMap(() => {
        return [GetCountries(), GetStates(), GetCities()];
      }),
    ),
  );

  getCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCountries),
      switchMap(() => {
        return this.http.get(ASSET_BASE_PATH + '/countries.json').pipe(
          switchMap((res: any) => {
            return [GetCountriesSuccess({countries: res})];
          }),
          catchError(() => {
            return of(GetCountriesFailed());
          }),
        );
      }),
    ),
  );

  getStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetStates),
      switchMap(() => {
        return this.http.get(ASSET_BASE_PATH + '/states.json').pipe(
          switchMap((res: any) => {
            return [GetStatesSuccess({states: res})];
          }),
          catchError(() => {
            return of(GetStatesFailed());
          }),
        );
      }),
    ),
  );

  getCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCities),
      switchMap(() => {
        return this.http.get(ASSET_BASE_PATH + '/cities.json').pipe(
          switchMap((res: any) => {
            return [GetCitiesSuccess({cities: res})];
          }),
          catchError(() => {
            return of(GetCitiesFailed());
          }),
        );
      }),
    ),
  );

  getMcc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMcc),
      switchMap(() => {
        return this.http
          .get(`${ASSET_BASE_PATH}/mcc_${this.language}.json`)
          .pipe(
            switchMap((res: any) => {
              return [GetMccSuccess({mcc: res})];
            }),
            catchError(() => {
              return of(GetMccFailed());
            }),
          );
      }),
    ),
  );
}
