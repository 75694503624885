import {inject} from '@angular/core';
import {TransferService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NzMessageService} from 'ng-zorro-antd/message';
import {catchError, switchMap, throwError} from 'rxjs';
import {UploadMediaCompleted} from '../../actions/upload.actions';

// TODO rimuovere nzmessageservice
export const uploadMediaCompleted = createEffect(
  (
    actions$ = inject(Actions),
    transferService = inject(TransferService),
    nzMessageService = inject(NzMessageService),
  ) => {
    return actions$.pipe(
      ofType(UploadMediaCompleted),
      switchMap((action) =>
        transferService
          .finalizeTransferMedia({
            transferId: action.transferId,
            mediaId: action.transferMediaId,
          })
          .pipe(
            catchError((error) => {
              nzMessageService.error(
                $localize`Oh-oh, unable to load all items.`,
              );
              return throwError(() => error);
            }),
          ),
      ),
    );
  },
  {functional: true, dispatch: false},
);
