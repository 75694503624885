import {inject} from '@angular/core';
import {TransferService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, switchMap} from 'rxjs';
import {ShowMessage} from '../../actions/layout.action';
import {Go} from '../../actions/router.action';
import {
  DuplicateTransfer,
  DuplicateTransferFailed,
  DuplicateTransferSuccess,
} from '../../actions/upload.actions';

export const duplicateTransfer = createEffect(
  (actions$ = inject(Actions), transferService = inject(TransferService)) => {
    return actions$.pipe(
      ofType(DuplicateTransfer),
      switchMap(({payload, transferId}) => {
        return transferService
          .duplicateTransfer({
            transferId,
            body: {...payload},
          })
          .pipe(
            switchMap((res) => [
              DuplicateTransferSuccess({payload: res}),
              Go({payload: {path: ['/', 'upload', 'progress']}}),
            ]),
            catchError(() => [
              DuplicateTransferFailed(),
              ShowMessage({
                payload: {type: 'error', message: $localize`Upload error`},
              }),
            ]),
          );
      }),
    );
  },
  {functional: true},
);
