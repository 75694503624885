import {inject} from '@angular/core';
import {AmplitudeService} from '@app/core/amplitude/amplitude.service';
import {UppyServices} from '@app/core/services/uppy.services';
import {UppyEvent, UppyItem} from '@app/shared/uppy';
import {AddedTransfer, Config} from '@generated/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Action, Store} from '@ngrx/store';
import {catchError, distinctUntilChanged, map, switchMap} from 'rxjs';
import {ShowMessage} from '../../actions/layout.action';
import {
  UpdateTransferMediaLoadingPercent,
  UploadMediaCompleted,
  UploadTransferMedia,
  UploadTransferMediaFailed,
  UploadTransferMediaSuccess,
} from '../../actions/upload.actions';
import {selectConfigurations} from '../../selectors/configurations.selector';

export const uploadTransferMedia = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    amplitude = inject(AmplitudeService),
    uppyServices = inject(UppyServices),
  ) => {
    return actions$.pipe(
      ofType(UploadTransferMedia),
      concatLatestFrom(() => [
        store.select(selectConfigurations),
        amplitude.deviceId(),
      ]),
      switchMap(
        ([action, configuration, deviceId]: [
          {addedTransfer: AddedTransfer},
          Config | null,
          string | undefined,
        ]) => {
          const transferId = action.addedTransfer.id;
          const transferMedia = action.addedTransfer.media;

          const file: UppyItem = {
            volume: transferMedia!.volume,
            size: transferMedia!.size,
            uploadId: transferMedia!.uploadId,
            meta: {
              transferId: transferId,
              transferMediaId: transferMedia?.id,
            },
            instance: 'transferMedia',
          };

          return uppyServices
            .uploadItems({
              files: [file],
              configuration: configuration!,
              deviceId,
            })
            .pipe(
              distinctUntilChanged(),
              map((res) => {
                const handlers: Record<UppyEvent['event'], () => Action> = {
                  'complete': () => UploadTransferMediaSuccess(),
                  'progress': () =>
                    UpdateTransferMediaLoadingPercent({
                      percent: res.progress ?? 0,
                    }),
                  'upload-success': () =>
                    UploadMediaCompleted({
                      transferId: file.meta?.transferId as string,
                      transferMediaId: file.meta?.transferMediaId as string,
                    }),
                };
                return handlers[res.event]();
              }),
              catchError(() => [
                UploadTransferMediaFailed(),
                ShowMessage({
                  payload: {
                    type: 'error',
                    message: $localize`Oh-oh, unable to load media picture.`,
                  },
                }),
              ]),
            );
        },
      ),
    );
  },
  {functional: true},
);
