import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { Store } from "@ngrx/store";
import { GetConfigurations } from "../store/actions/configuration.action";

export const configurationResolver: ResolveFn<boolean> = () => {
  const store = inject(Store);
  store.dispatch(GetConfigurations());
  return true;
};
