import {Injectable} from '@angular/core';
import {IamSession} from '@fullstackagency/iam';
import {UserStripeDashboardUrl, UserStripeOnboardUrl} from '@generated/models';
import {User} from '@generated/models/user';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {NzMessageService} from 'ng-zorro-antd/message';
import {from, iif} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {
  SetRestrictedAreaSettingsActiveTab,
  ShowMessage,
} from '../actions/layout.action';
import {Go, Redirect} from '../actions/router.action';
import {
  CheckSession,
  CheckSessionFailed,
  CheckSessionSuccess,
  CreateMeOauth,
  CreateMeOauthFailed,
  CreateMeOauthSuccess,
  DeleteMe,
  DeleteMeFailed,
  DeleteMeSuccess,
  DeleteOauthClientCredentials,
  DeleteOauthClientCredentialsFailed,
  DeleteOauthClientCredentialsSuccess,
  GetMe,
  GetMeFail,
  GetMeSuccess,
  GetOauthClientCredentialsList,
  GetOauthClientCredentialsListFailed,
  GetOauthClientCredentialsListSuccess,
  GetOnboardUrl,
  GetOnboardUrlFail,
  GetOnboardUrlSuccess,
  GetStripeDashboardUrl,
  GetStripeDashboardUrlFailed,
  GetStripeDashboardUrlSuccess,
  SetUserOnboard,
  SetUserOnboardFailed,
  SetUserOnboardSuccess,
  UpdateBillingInfo,
  UpdateBillingInfoFailed,
  UpdateBillingInfoSuccess,
  UpdateMeContactPreference,
  UpdateMeContactPreferenceFailed,
  UpdateMeContactPreferenceSuccess,
  UpdateMeLanguage,
  UpdateMeLanguageFailed,
  UpdateMeLanguageSuccess,
} from '../actions/user.action';
import {selectUser} from '../selectors/user.selector';
import {UserService} from '@generated/services';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private readonly userService: UserService,
    private readonly iamSession: IamSession,
    private nzMessageService: NzMessageService,
    private readonly store: Store
  ) {}

  readMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMe),
      switchMap(() =>
        this.userService.readMe().pipe(
          map((user: User) => GetMeSuccess({user})),
          catchError(() => [
            ShowMessage({
              payload: {
                type: 'error',
                message: $localize`User not found`,
              },
            }),
            GetMeFail(),
          ])
        )
      )
    )
  );

  deleteMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMe),
      switchMap(() =>
        this.userService.deleteMe().pipe(
          switchMap(() => [
            DeleteMeSuccess(),
            Go({
              payload: {
                path: ['/', 'account', 'logout'],
              },
            }),
          ]),
          catchError(() => {
            this.nzMessageService.error($localize`Unable to delete`);
            return [DeleteMeFailed()];
          })
        )
      )
    )
  );

  updateMeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateMeLanguage),
      switchMap((action) =>
        this.userService.updateUserLanguage({body: action.dto}).pipe(
          switchMap((user) => [UpdateMeLanguageSuccess({user})]),
          catchError(() => [UpdateMeLanguageFailed()])
        )
      )
    )
  );

  updateMeContactPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateMeContactPreference),
      switchMap((action) =>
        this.userService.updateUserContactPreference({body: action.dto}).pipe(
          switchMap((user) => [UpdateMeContactPreferenceSuccess({user})]),
          catchError(() => [UpdateMeContactPreferenceFailed()])
        )
      )
    )
  );

  checkSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckSession),
      switchMap(() =>
        from(this.iamSession.exists()).pipe(
          switchMap((exists) =>
            iif(
              () => exists,
              [CheckSessionSuccess(), GetMe()],
              [CheckSessionFailed()]
            )
          )
        )
      )
    )
  );

  onboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetOnboardUrl),
      concatLatestFrom(() => this.store.select(selectUser)),
      switchMap(([action, user]) =>
        iif(
          () => !!user?.billingInfo,
          this.userService
            .stripeOnboard({
              returnUrl: action.returnUrl,
            })
            .pipe(
              switchMap((payload: UserStripeOnboardUrl) => [
                GetOnboardUrlSuccess(),
                Redirect({url: payload.url}),
              ]),
              catchError(() => {
                this.nzMessageService.error($localize`User not found`);
                return [GetOnboardUrlFail()];
              })
            ),
          [
            SetRestrictedAreaSettingsActiveTab({tabIndex: 2}),
            Go({payload: {path: ['/', 'restricted-area', 'settings']}}),
            ShowMessage({
              payload: {
                type: 'info',
                message: $localize`Please fill billing info`,
              },
            }),
          ]
        )
      )
    )
  );

  stripeDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetStripeDashboardUrl),
      switchMap(() =>
        this.userService.userStripeDashboard().pipe(
          switchMap((payload: UserStripeDashboardUrl) => [
            GetStripeDashboardUrlSuccess(),
            Redirect({url: payload.url}),
          ]),
          catchError(() => [GetStripeDashboardUrlFailed()])
        )
      )
    )
  );

  createMeOauth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateMeOauth),
      switchMap((action) =>
        this.userService.addOauthClientCredentials({body: action.dto}).pipe(
          switchMap((res) => [
            CreateMeOauthSuccess({res}),
            ShowMessage({
              payload: {
                type: 'success',
                message: $localize`Token generated successfully`,
              },
            }),
            GetOauthClientCredentialsList(),
          ]),
          catchError(() => [CreateMeOauthFailed()])
        )
      )
    )
  );

  getOauthClientCredentialsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetOauthClientCredentialsList),
      switchMap((action) =>
        this.userService.listOauthClientCredentials().pipe(
          switchMap((list) => [GetOauthClientCredentialsListSuccess({list})]),
          catchError(() => [GetOauthClientCredentialsListFailed()])
        )
      )
    )
  );

  deleteOauthClientCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteOauthClientCredentials),
      switchMap((action) =>
        this.userService
          .deleteOauthClientCredentials({clientId: action.clientId})
          .pipe(
            switchMap(() => [
              DeleteOauthClientCredentialsSuccess(),
              GetOauthClientCredentialsList(),
              ShowMessage({
                payload: {
                  type: 'success',
                  message: $localize`Token removed successfully`,
                },
              }),
            ]),
            catchError(() => [DeleteOauthClientCredentialsFailed()])
          )
      )
    )
  );

  updateBillingInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateBillingInfo),
      switchMap((action) =>
        this.userService.updateUserBillingInfo({body: action.dto}).pipe(
          switchMap((user) => [
            UpdateBillingInfoSuccess({user}),
            ShowMessage({
              payload: {
                type: 'success',
                message: $localize`Billing info updated successfully`,
              },
            }),
          ]),
          catchError(() => [
            UpdateBillingInfoFailed(),
            ShowMessage({
              payload: {
                type: 'error',
                message: $localize`Something went wrong. Check the inserted information`,
              },
            }),
          ])
        )
      )
    )
  );

  setUserOnboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetUserOnboard),
      switchMap((action) =>
        this.userService.userOnboard({body: action.dto}).pipe(
          switchMap((user) => [
            SetUserOnboardSuccess({user}),
            Go({
              payload: {
                path: ['/restricted-area'],
              },
            }),
          ]),
          catchError(() => [
            SetUserOnboardFailed(),
            ShowMessage({
              payload: {
                type: 'error',
                message: $localize`Something went wrong. Check the inserted information`,
              },
            }),
          ])
        )
      )
    )
  );
}
