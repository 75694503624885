import {inject} from '@angular/core';
import {AddedTransfer} from '@generated/models';
import {TransferService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, switchMap} from 'rxjs';
import {
  AddTransferMedia,
  AddTransferMediaFailed,
  UpdateTransferMediaLoadingPercent,
  UploadTransferMedia,
} from '../../actions/upload.actions';

export const addTransferMedia = createEffect(
  (actions$ = inject(Actions), transferService = inject(TransferService)) => {
    return actions$.pipe(
      ofType(AddTransferMedia),
      switchMap((action) =>
        transferService
          .addTransferMedia({
            transferId: action.transferId,
            body: action.dto,
          })
          .pipe(
            mergeMap((addedTransfer: AddedTransfer) => [
              UploadTransferMedia({addedTransfer}),
              UpdateTransferMediaLoadingPercent({
                percent: 0,
              }),
            ]),
            catchError(() => {
              return [AddTransferMediaFailed()];
            }),
          ),
      ),
    );
  },
  {functional: true},
);
