import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {hasCapability} from '@app/shared/functions/has-capability';
import {HasCapabilityPipe} from '@app/shared/pipes/has-capability.pipe';
import {SharedModule} from '@app/shared/shared.module';
import {User, UserCapability} from '@generated/models';
import {Observable, combineLatest, filter, map, startWith} from 'rxjs';
import {ToastComponent} from '../shared/components/toast/toast.component';
import {EmailVerificationRequiredComponent} from './components/email-verification-required.component';
import {FooterComponent} from './components/footer.component';
import {MainTopbarComponent} from './components/main-topbar.component';
import {MainLayoutData} from './interfaces/main-layout-data.interface';

@Component({
  selector: 'app-layout',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    MainTopbarComponent,
    FooterComponent,
    ToastComponent,
    EmailVerificationRequiredComponent,
    HasCapabilityPipe,
  ],
  template: `
    <div class="flex flex-col h-full">
      <header>
        <app-main-topbar
          [sessionExists]="sessionExists$ | async"
          [user]="user$ | async"
          [loading]="(loading$ | async) ?? true"
        />
      </header>
      <div class="flex-1">
        <div class="h-full">
          @if (emailVerificationRequired$ | async) {
            <app-email-verification-required />
          }
          <div class="h-full">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
      <footer>
        <app-footer />
      </footer>
    </div>
  `,
})
export class MainLayoutComponent {
  private readonly router: Router = inject(Router);
  private readonly route: ActivatedRoute = inject(ActivatedRoute);

  readonly data: MainLayoutData = this.route.snapshot.data['data'];
  readonly user$: Observable<User | null> = this.data.user$;
  readonly sessionExists$: Observable<boolean | null> =
    this.data.sessionExists$;
  readonly loading$: Observable<boolean> = this.data.loading$;

  readonly emailVerificationRequired$: Observable<boolean> = combineLatest({
    sessionExists: this.sessionExists$,
    user: this.user$,
  }).pipe(
    map(
      ({sessionExists, user}) =>
        !!sessionExists &&
        !!user &&
        !hasCapability(user.capabilities, UserCapability.CanSendTransfer),
    ),
  );

  readonly fullScreen$: Observable<boolean> = this.router.events.pipe(
    startWith(null),
    filter((event) => event instanceof NavigationEnd || event === null),
    map(() => {
      const lastChild = this.#lastChild(this.route);
      return !!lastChild.snapshot.data['fullscreen'];
    }),
  );

  #lastChild(route: ActivatedRoute): ActivatedRoute {
    return route.firstChild ? this.#lastChild(route.firstChild) : route;
  }
}
