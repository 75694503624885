import {inject} from '@angular/core';
import {AddedTransfer} from '@generated/models';
import {TransferService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {catchError, switchMap} from 'rxjs';
import {ShowMessage} from '../../actions/layout.action';
import {Go} from '../../actions/router.action';
import {
  CheckEmailCode,
  CheckEmailCodeFailed,
  CheckEmailCodeSuccess,
} from '../../actions/upload.actions';
import {selectUploadResponse} from '../../selectors/upload.selector';

export const checkEmailCode = createEffect(
  (
    actions$ = inject(Actions),
    transferService = inject(TransferService),
    store = inject(Store),
  ) => {
    return actions$.pipe(
      ofType(CheckEmailCode),
      concatLatestFrom(() => [store.select(selectUploadResponse)]),
      switchMap(
        ([action, uploadResponse]: [
          ReturnType<typeof CheckEmailCode>,
          AddedTransfer | null,
        ]) => {
          if (uploadResponse && uploadResponse.id && action.payload) {
            return transferService
              .verifyTransferSender({
                transferId: uploadResponse?.id,
                body: action.payload,
              })
              .pipe(
                switchMap((res) => [
                  CheckEmailCodeSuccess({payload: res}),
                  Go({
                    payload: {
                      path: ['/', 'upload', 'progress'],
                      extras: {replaceUrl: true},
                    },
                  }),
                ]),
                catchError(() => [
                  CheckEmailCodeFailed(),
                  ShowMessage({
                    payload: {
                      type: 'error',
                      message: $localize`Check your code. It might have expired or simply be incorrect`,
                    },
                  }),
                ]),
              );
          } else {
            return [
              CheckEmailCodeFailed(),
              ShowMessage({
                payload: {
                  type: 'error',
                  message: $localize`Check your code. It might have expired or simply be incorrect`,
                },
              }),
            ];
          }
        },
      ),
    );
  },
  {functional: true},
);
