import {Component} from '@angular/core';
import {SharedModule} from '@app/shared/shared.module';
import {GuestMenuItemsComponent} from './guest-menu-items.component';
import {MenuDrawerComponent} from './menu-drawer.component';

@Component({
  selector: 'app-guest-menu',
  standalone: true,
  imports: [SharedModule, GuestMenuItemsComponent, MenuDrawerComponent],
  template: `
    <div class="flex justify-end gap-12">
      <div class="hidden md:block">
        <app-guest-menu-items direction="row" />
      </div>
      <div class="flex gap-4">
        <a
          class="!border-white md:min-w-25"
          [routerLink]="['/', 'account', 'login']"
          #loginOption="routerLinkActive"
          routerLinkActive
          nz-button
          nzType="primary"
          [ngClass]="{'!hidden': loginOption.isActive}"
        >
          <span i18n>Log in</span>
        </a>
        <a
          class="btn-white md:min-w-25"
          [routerLink]="['/', 'account', 'sign-up']"
          #signupOption="routerLinkActive"
          routerLinkActive
          nz-button
          [ngClass]="{'!hidden': signupOption.isActive}"
        >
          <span i18n>Sign up</span>
        </a>
        <div class="md:hidden">
          <app-menu-drawer>
            <app-guest-menu-items direction="col" />
          </app-menu-drawer>
        </div>
      </div>
    </div>
  `,
})
export class GuestMenuComponent {}
