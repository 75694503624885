import {Component, Input} from '@angular/core';
import {UiText, UiTextTypeEnum} from '@ory/client';

@Component({
  selector: 'iam-with-ngzorro-messages',
  template: `
    <span
      *ngFor="let message of messages"
      [ngClass]="{
        'text-red': message.type === UiTextTypeEnum.Error,
      }"
    >
      {{ message.text }}
    </span>
  `,
})
export class MessagesComponent {
  @Input() messages!: UiText[];
  readonly UiTextTypeEnum = UiTextTypeEnum;
}
