import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {selectSessionExists} from '@app/core/store/selectors/user.selector';
import {Store} from '@ngrx/store';
import {map, take, tap} from 'rxjs';
import {CheckSession} from './core/store/actions/user.action';

export const canActivateApp: CanActivateFn = () => {
  const store = inject(Store);
  return store.select(selectSessionExists).pipe(
    take(1),
    tap((sessionExists) => {
      if (sessionExists === null) {
        store.dispatch(CheckSession());
      }
    }),
    map(() => true),
  );
};
