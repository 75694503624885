import {inject} from '@angular/core';
import {AmplitudeService} from '@app/core/amplitude/amplitude.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {switchMap} from 'rxjs';
import {ClearUser} from '../../actions/user.action';

export const clearUser = createEffect(
  (actions$ = inject(Actions), amplitude = inject(AmplitudeService)) => {
    return actions$.pipe(
      ofType(ClearUser),
      switchMap(() => amplitude.loaded$),
      switchMap((loaded) => [loaded && amplitude.isLoaded && amplitude.reset()])
    );
  },
  {functional: true, dispatch: false}
);
