import {Component, Input} from '@angular/core';
import {iconProvider} from '@app/shared/icon.module';
import {SharedModule} from '@app/shared/shared.module';
import {FtShopOutline} from '@icons/FtShopOutline';
import {FtSwapOutline} from '@icons/FtSwapOutline';
import {MenuItem, MenuItemComponent} from './menu-item.component';

@Component({
  selector: 'app-user-menu-nav-items',
  standalone: true,
  imports: [SharedModule, MenuItemComponent],
  providers: [iconProvider([FtSwapOutline, FtShopOutline])],
  template: `
    @if (direction === 'col') {
      <div class="flex flex-col gap-6">
        @for (item of items.slice().reverse(); track $index) {
          <app-menu-item [item]="item" />
        }
      </div>
    } @else {
      <div>
        <ul class="flex items-center gap-4 m-0">
          @for (item of items; track $index) {
            <li>
              <a
                nz-button
                nzType="text"
                routerLinkActive
                #liActive="routerLinkActive"
                [ngClass]="{
                  '!text-purple': liActive.isActive,
                  '!text-white': !liActive.isActive,
                }"
                [routerLink]="item.url"
              >
                <span nz-icon [nzType]="item.nzIcon" class="text-base"></span
                ><span>{{ item.label }}</span>
              </a>
            </li>
          }
        </ul>
      </div>
    }
  `,
})
export class UserMenuNavItemsComponent {
  @Input() direction: 'col' | 'row' = 'row';

  readonly items: MenuItem[] = [
    {
      label: $localize`Transfers`,
      url: ['/', 'restricted-area', 'transfers'],
      nzIcon: 'ft-swap',
    },
    {
      label: $localize`Your shop`,
      url: ['/', 'restricted-area', 'shop'],
      nzIcon: 'ft-shop',
    },
  ];
}
