import {Injectable, LOCALE_ID, inject} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {LANGUAGE} from '../constants';
import {Language} from '@generated/models';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private readonly meta = inject(Meta);
  private readonly language = inject(LANGUAGE);

  updateMetaTag() {
    this.meta.updateTag({
      name: 'description',
      content: $localize`The platform that empowers you to monetize your digital content simply by sharing it. Discover the easiest way to turn your files into a revenue stream.`,
    });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://fulltransfer.io',
    });
    this.meta.updateTag({property: 'og:type', content: 'website'});
    this.meta.updateTag({
      property: 'og:title',
      content: $localize`Fulltransfer - Earn from sharing your file.`,
    });
    this.meta.updateTag({
      property: 'og:description',
      content: $localize`The platform that empowers you to monetize your digital content simply by sharing it. Discover the easiest way to turn your files into a revenue stream.`,
    });

    const ogImage =
      this.language === Language.It
        ? 'assets/img/og4-en.jpg'
        : 'assets/img/og4-en.jpg';
    this.meta.updateTag({property: 'og:image', content: ogImage});

    this.meta.updateTag({name: 'twitter:card', content: 'summary_large_image'});
    this.meta.updateTag({
      property: 'twitter:domain',
      content: 'fulltransfer.io',
    });
    this.meta.updateTag({
      property: 'twitter:url',
      content: 'https://fulltransfer.io',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: $localize`Fulltransfer - Earn from sharing your file.`,
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: $localize`The platform that empowers you to monetize your digital content simply by sharing it.`,
    });
    this.meta.updateTag({name: 'twitter:image', content: ''});
  }
}
