import {inject} from '@angular/core';
import {CanMatchFn, Router} from '@angular/router';
import {
  CheckSession,
  CheckSessionFailed,
  CheckSessionSuccess,
} from '@app/core/store/actions/user.action';
import {selectSessionExists} from '@app/core/store/selectors/user.selector';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {iif, of, race} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

export const canMatchRestrictedArea: CanMatchFn = () => {
  const router = inject(Router);
  const store = inject(Store);
  const action$ = inject(Actions);

  const checkSessionFailed$ = action$.pipe(
    ofType(CheckSessionFailed),
    take(1),
    map(() => router.createUrlTree(['/', 'account', 'login']))
  );

  const checkSessionSuccess$ = action$.pipe(
    ofType(CheckSessionSuccess),
    take(1),
    map(() => true)
  );
  return store.select(selectSessionExists).pipe(
    take(1),
    switchMap((sessionExists: boolean | null) =>
      iif(
        () => {
          if (sessionExists !== true) {
            store.dispatch(CheckSession());
          }
          return sessionExists === true;
        },
        of(true),
        race(checkSessionFailed$, checkSessionSuccess$)
      )
    )
  );
};
