import {ChangeDetectionStrategy, Component} from '@angular/core';
import {environment} from '@app/env/environment';
import pck from '../../../../../../package.json';

@Component({
  selector: 'app-footer',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="relative h-0">
      <div
        class="absolute bottom-0 h-14 w-full text-black text-2xs font-medium flex justify-center py-4"
      >
        <div class="flex items-center justify-center">
          <a class="underline" [href]="siteEndpoint + 'credits/'" i18n
            >Credits</a
          >
          <span>&nbsp;&bullet;&nbsp;</span>
          <a class="underline" [href]="siteEndpoint + 'privacy/'" i18n
            >Privacy</a
          >
          <span>&nbsp;&bullet;&nbsp;</span>
          <a
            class="underline"
            [href]="siteEndpoint + 'termini-e-condizioni-di-utilizzo/'"
            i18n
            >Terms</a
          >
        </div>
      </div>
    </div>
  `,
})
export class FooterComponent {
  readonly siteEndpoint = environment.siteUrl;
  readonly release: string = pck.version;
}
