import {NgModule, inject} from '@angular/core';
import {EyeInvisibleOutline, EyeOutline} from '@ant-design/icons-angular/icons';
import {environment} from '@app/env/environment';
import {iconProvider} from '@app/shared/icon.module';
import {SharedModule} from '@app/shared/shared.module';
import {IamModule, OPTIONS, en, it} from '@fullstackagency/iam';
import {Language} from '@generated/models';
import {NzInputModule} from 'ng-zorro-antd/input';
import {LANGUAGE} from '../constants';
import {ContentComponent} from './components/content.component';
import {MessagesComponent} from './components/messages.component';

@NgModule({
  declarations: [ContentComponent, MessagesComponent],
  exports: [ContentComponent, MessagesComponent],
  imports: [SharedModule, IamModule, NzInputModule],
  providers: [
    {
      provide: OPTIONS,
      useFactory: () => ({
        ...(inject(LANGUAGE) === Language.It
          ? {
              i18n: {
                ...it,
                1070004: 'E-Mail',
                1070002: 'E-Mail',
                1050001: 'Le tue modifiche sono state salvate!',
                1080002: 'Hai verificato correttamente la tua email.',
                1060003:
                  "Un codice di recupero è stato inviato all'indirizzo email fornito. Se non l'hai ricevuto, controlla che l'indirizzo sia corretto e che sia quello con cui ti sei registrato.",
                4000006:
                  "Le credenziali fornite non sono valide. Controlla eventuali errori di battitura nella password o nell'indirizzo email.",
                4000007: 'Un account con la stessa e-mail esiste già.',
                4070006:
                  'Il codice inserito non è valido o è stato già usato. Ritenta.',
                903: 'è troppo simile alla tua email',
              },
            }
          : {
              i18n: {
                ...en,
                1070002: 'E-Mail',
                1070004: 'E-Mail',
                1060003:
                  "A recovery code has been sent to the provided email address. If you haven't received it, check the address for errors and ensure it's the one you registered with.",
                4000006:
                  'The provided credentials are invalid, check for spelling mistakes in your password or email address.',
                4000007: 'An account with the same e-mail exists already.',

                903: 'is too similar to your email',
              },
            }),
        client: {
          basePath: environment['iam'].basePath,
        },
        paths: environment['iam'].paths,
      }),
    },
    iconProvider([EyeOutline, EyeInvisibleOutline]),
  ],
})
export class IamWithNgZorroModule {}
