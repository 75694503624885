import {inject} from '@angular/core';
import {AmplitudeService} from '@app/core/amplitude/amplitude.service';
import {TransferStatus, User} from '@generated/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  Observable,
  concatMap,
  filter,
  iif,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';
import {AddTransferSuccess} from '../../actions/upload.actions';
import {selectSessionExists, selectUser} from '../../selectors/user.selector';

export const transferCreated = createEffect(
  (
    actions$ = inject(Actions),
    amplitude = inject(AmplitudeService),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(AddTransferSuccess),
      switchMap(
        (
          action
        ): Observable<{
          action: ReturnType<typeof AddTransferSuccess>;
          user: User | null;
        }> => {
          return store.select(selectSessionExists).pipe(
            filter(
              (sessionExists): sessionExists is boolean =>
                sessionExists !== null
            ),
            take(1),
            switchMap((sessionExists) => {
              return iif(
                () => sessionExists,
                store.select(selectUser).pipe(
                  filter((user) => user !== null),
                  take(1),
                  map((user) => ({action, user}))
                ),
                of({action, user: null})
              );
            })
          );
        }
      ),
      concatMap(
        (prev: {
          action: ReturnType<typeof AddTransferSuccess>;
          user: User | null;
        }) =>
          amplitude.loaded$.pipe(
            take(1),
            map((loaded) => ({...prev, loaded}))
          )
      ),
      switchMap(
        ({
          user,
          action,
          loaded,
        }: {
          action: ReturnType<typeof AddTransferSuccess>;
          user: User | null;
          loaded: boolean;
        }) => [
          loaded &&
            amplitude.isLoaded &&
            amplitude.transferCreated(
              {
                transferId: action.payload?.id!,
                transferIsPasswordProtected: action.payload?.passwordProtected!,
                transferStatus: TransferStatus.Initialized, //action.payload?.status!,
                transferType: action.payload?.type!,
                transferIsPaid: action.payload?.paid!,
                transferExpiresAfter: action.payload?.expiresAfter!,
              },
              {user_id: user?.id}
            ),
        ]
      )
    );
  },
  {functional: true, dispatch: false}
);
